<template>
  <a-card :bordered="false">
    <div class="app-container">

      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="6" :sm="24">
              <a-form-item label="条形码">
                <a-input v-model="queryParam.barcode" allow-clear placeholder="请输入商品条形码" />
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="商品名称">
                <a-input v-model="queryParam.brief" allow-clear placeholder="请输入商品名称" />
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <div class="table-operator">
                <a-button type="primary" icon="search" @click="$refs.table.refresh(true)">查找</a-button>
                <a-button type="primary" icon="plus" @click="handleCreate">添加</a-button>
                <a-button type="primary" icon="import" @click="handleImport">导入</a-button>
              </div>
            </a-col>
          </a-row>
        </a-form>
      </div>

      <!-- 查询结果 -->
      <s-table
        ref="table"
        size="small"
        :loading="loading"
        :columns="columns"
        :pageNum="pageNum"
        :data="loadData"
        :scroll="{ x: 1000 }"
        rowKey="id">

        <span slot="serial" slot-scope="text, record, index">{{ index + 1 }}</span>
        <span slot="img" slot-scope="img">
          <a-avatar
            slot="img"
            :src="img"
            shape="square"
            @click="openPreview(img)"
          />
        </span>

        <div slot="price_render" slot-scope="text">
          {{ text / 100 | numberFormat }}
        </div>
        <div slot="show_on_index" slot-scope="text, record">
          <a-switch @change="showConfirm(record.id, text, 'show_on_index')" :checked="text"/>
        </div>
        <div slot="is_sale" slot-scope="text, record">
          <a-switch @change="showConfirm(record.id, text, 'is_sale')" :checked="text"/>
        </div>
        <div slot="is_new" slot-scope="text, record">
          <a-switch @change="showConfirm(record.id, text, 'is_new')" :checked="text"/>
        </div>
        <div slot="is_hot" slot-scope="text, record">
          <a-switch @change="showConfirm(record.id, text, 'is_hot')" :checked="text"/>
        </div>
        <span slot="action" slot-scope="text, record">
          <template>
            <a @click="handleVipPrice(record)">会员价</a>
            <a-divider type="vertical" />
            <a @click="handleUpdate(record)">编辑</a>
            <a-divider type="vertical" />
            <a-popconfirm
              title="确定要删除么？"
              ok-text="确定"
              cancel-text="取消"
              @confirm="handleDelete(record)"
            >
              <a>删除</a>
            </a-popconfirm>
          </template>
        </span>
      </s-table>

      <a-modal v-model="previewVisible" title="预览" width="350px" :footer="null">
        <img alt="example" style="width: 300px; margin: 0 auto" :src="previewurl" />
      </a-modal>
      <create-goods-import-form
        ref="goodsImportModal"
        v-if="import_form_visible"
        :visible.sync="import_form_visible"
        :loading="importConfirmLoading"
        @cancel="handleImportCancel"
        @ok="handleImportOk" />
    </div>
  </a-card>
</template>
<script>
import { listGoods, deleteGoods, editGoods, goodsDataCompressImport } from '@/api/applet_goods'
import { STable } from '@/components'
import CreateGoodsImportForm from './CreateGoodsImportForm'
const { MessageBox } = require('element-ui')
export default {
  name: 'GoodsList',
  components: {
    STable,
    CreateGoodsImportForm
  },
  data () {
    return {
      pageNum: 1,
      list: [],
      total: 0,
      loading: false,
      units: this.$Dictionaries.unit,
      goods_type: this.$Dictionaries.goods_type,
      previewurl: '',
      previewVisible: false,
      import_form_visible: false,
      importConfirmLoading: false,
      listLoading: true,
      queryParam: {},
      goodsDetail: '',
      detailDialogVisible: false,
      downloadLoading: false,
      // 表头
      columns: [
        {
          title: '序号',
          width: '100px',
          dataIndex: 'serial',
          fixed: 'left'
        },
        {
          title: '商品名称',
          dataIndex: 'brief',
          width: '150px',
          fixed: 'left',
          ellipsis: true
        },
        {
          title: '条形码',
          dataIndex: 'barcode',
          width: '150px',
          ellipsis: true
        },
        {
          title: '图片',
          dataIndex: 'picture',
          width: '150px',
          scopedSlots: { customRender: 'img' }
        },
        {
          title: '品牌',
          dataIndex: 'brand',
          width: '150px',
          ellipsis: true
        },
        {
          title: '原价',
          dataIndex: 'tag_price',
          align: 'center',
          width: '150px',
          scopedSlots: { customRender: 'price_render' }
        },
        {
          title: '现价',
          dataIndex: 'price',
          align: 'center',
          width: '150px',
          scopedSlots: { customRender: 'price_render' }
        },
        {
          title: '单位',
          dataIndex: 'unit',
          align: 'center',
          width: '100px',
          customRender: (text) => this.units[text] ? this.units[text] : '-'
        },
        {
          title: '商品类型',
          dataIndex: 'goods_type',
          align: 'center',
          width: '100px',
          customRender: (text) => this.goods_type[text] ? this.goods_type[text] : '-'
        },
        {
          title: '是否上架',
          dataIndex: 'is_sale',
          width: '100px',
          scopedSlots: { customRender: 'is_sale' }
        },
        {
          title: '是否热销',
          dataIndex: 'is_hot',
          width: '100px',
          scopedSlots: { customRender: 'is_hot' }
        },
        {
          title: '是否新品',
          width: '100px',
          dataIndex: 'is_new',
          scopedSlots: { customRender: 'is_new' }
        },
        {
          title: '是否首页',
          dataIndex: 'show_on_index',
          width: '100px',
          scopedSlots: { customRender: 'show_on_index' }
        },
        {
          title: '操作',
          dataIndex: 'action',
          fixed: 'right',
          width: '280px',
          align: 'center',
          scopedSlots: { customRender: 'action' }
        }
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        return listGoods(Object.assign(parameter, this.queryParam))
          .then(res => {
            console.log(res.data)
            return res.data
          })
      },
      selectedRowKeys: [],
      selectedRows: [],

      options: {
        alert: {
          show: true,
          clear: () => {
            this.selectedRowKeys = []
          }
        },
        rowSelection: {
          selectedRowKeys: this.selectedRowKeys,
          onChange: this.onSelectChange
        }
      }
    }
  },
  created () {
    this.setCurrentPage()
  },
  methods: {
    setCurrentPage () {
      // const page = sessionStorage.getItem('CurrentPage')
      const page = this.$store.getters.pop
      if (page) {
        console.log(page)
        const current = page.split('#$$#')[0]
        const query = page.split('#$$#')[1]
        const path = page.split('#$$#')[2]
        sessionStorage.removeItem('CurrentPage')
        if (path === this.$route.path) {
          if (!isNaN(Number(current))) {
            this.pageNum = Number(current)
          }
          if (query) {
            this.queryParam = JSON.parse(query)
          }
        } else {
          this.queryParam = {}
          this.$store.dispatch('clear')
        }
      }
    },
    upDownTitle (record) {
      return '确定要' + (record.is_sale ? '下架' : '上架') + '吗？'
    },
    handleCreate () {
      this.$router.push({ path: '/applet_goods/create' })
    },
    showConfirm (id, checked, tag) {
      console.log(id, checked)
      const goods = {}
      goods[tag] = !checked
      this.editGoods(goods, id)
    },
    editGoods (goods, id) {
      this.loading = true
      editGoods(goods, id)
        .then(response => {
          if (response.code === 1000) {
            this.$refs.table.refresh()
          }
        })
        .catch(response => {
          console.log(response)
        }).finally(() => {
          this.loading = false
      })
    },
    handleUpdate (row) {
      // sessionStorage.setItem('CurrentPage', this.$refs.table.getCurrent() + '#$$#' + JSON.stringify(this.queryParam) + '#$$#' + this.$route.path)
      this.$store.dispatch('push', this.$refs.table.getCurrent() + '#$$#' + JSON.stringify(this.queryParam) + '#$$#' + this.$route.path)
      this.$router.push({ path: '/applet_goods/edit', query: { id: row.id } })
    },
    handleVipPrice (row) {
      sessionStorage.setItem('CurrentPage', this.$refs.table.getCurrent() + '#$$#' + JSON.stringify(this.queryParam) + '#$$#' + this.$route.path)
      this.$router.push({ path: '/applet_goods/vip_price', query: { id: row.id, barcode: row.barcode } })
    },
    handleGallery (row) {
      sessionStorage.setItem('CurrentPage', this.$refs.table.getCurrent() + '#$$#' + JSON.stringify(this.queryParam) + '#$$#' + this.$route.path)
      this.$router.push({ path: '/applet_goods/gallery', query: { id: row.id } })
    },
    handleSale (row) {
      console.log(row)
      editGoods({ is_sale: !row.is_sale }, row.id)
        .then(response => {
          this.$refs.table.refresh()
        })
        .catch(response => {
          MessageBox.alert('错误：' + response, '警告', {
            confirmButtonText: '确定',
            type: 'error'
          })
        })
    },
    openPreview (url) {
      if (url === undefined || url === '') {
        return
      }
      this.previewVisible = true
      this.previewurl = url
    },
    showDetail (detail) {
      this.goodsDetail = detail
      this.detailDialogVisible = true
    },
    handleDelete (row) {
      deleteGoods(row.id).then(response => {
        this.$refs.table.refresh()
      }).catch(response => {
        console.log(response)
      })
    },
    handleImport () {
      this.import_form_visible = true
    },
    handleImportCancel () {
      this.import_form_visible = false
      const form = this.$refs.goodsImportModal.form
      form.resetFields() // 清理表单数据（可不做）
    },
    handleImportOk (fileList) {
      const form = this.$refs.goodsImportModal.form
      this.importConfirmLoading = true
      form.validateFields((errors, values) => {
        if (!errors) {
          // 新增
          const formData = new FormData()
          formData.append('file', fileList[0])
          goodsDataCompressImport(formData).then(res => {
            console.log(res)
            // 刷新表格
            this.$refs.table.refresh()
            if (res.code === 1000) {
              this.$refs.table.refresh()
            }
          }).finally(() => {
            this.import_form_visible = false
            this.importConfirmLoading = false
            form.resetFields()
          })
        } else {
          this.importConfirmLoading = false
        }
      })
    }
  }
}
</script>
<style>
.table-expand {
  font-size: 0;
  padding-left: 60px;
}

.table-expand label {
  width: 100px;
  color: #99a9bf;
}

.table-expand .a-form-item {
  margin-right: 0;
  margin-bottom: 0;
}

.gallery {
  width: 80px;
  margin-right: 10px;
}

.goods-detail-box img {
  width: 100%;
}
</style>
