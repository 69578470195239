<template>
  <div>
    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="24">
          <a-col :md="6" :sm="24">
            <a-form-item label="货品名称">
              <a-input v-model="queryParam.name" placeholder="请输入名称" @keyup.enter.native="$refs.table.refresh(true)" allow-clear/>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="24">
            <a-form-item label="条形码">
              <a-input v-model="queryParam.barcode" placeholder="请输入完整的编码" @keyup.enter.native="$refs.table.refresh(true)" allow-clear/>
            </a-form-item>
          </a-col>
          <a-col :md="2" :sm="24">
            <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <div class="table-operator">
      <a-button type="primary" icon="plus" @click="handleAdd">新建</a-button>
    </div>

    <s-table
      ref="table"
      size="small"
      rowKey="id"
      :scroll="{ x: 2000 }"
      :loading="loading"
      :columns="columns"
      :pageNum="pageNum"
      :data="loadData"
    >
      <span slot="serial" slot-scope="text, record, index">
        {{ index + 1 }}
      </span>
      <div slot="count_render" slot-scope="text" style="text-align: right; min-width: 150px; max-width: 150px">
        {{ text }}
      </div>
      <span slot="action" slot-scope="text, record">
        <template>
          <a @click="handleGoodsDeclaration(record)" v-if="record.open_consumption_tax === 2">消费税</a>
          <a-divider type="vertical" v-if="record.open_consumption_tax === 2"/>
          <a @click="handleEdit(record)">编辑</a>
          <a-divider type="vertical"/>
          <a-popconfirm
            title="确定要删除么？"
            ok-text="确定"
            cancel-text="取消"
            @confirm="handleDelete(record)"
          >
            <a>删除</a>
          </a-popconfirm>
        </template>
      </span>
    </s-table>
    <create-form
      ref="createModal"
      v-if="visible"
      :visible.sync="visible"
      :loading="confirmLoading"
      :model="mdl"
      @cancel="handleAddCancel"
      @ok="handleOk"
    />
  </div>
</template>

<script>
import { STable } from '@/components'
import CreateForm from '@/views/c-wms/goods_declaration/modules/CreateForm'
import { goods_declaration_create, goods_declaration_delete, goods_declaration_list } from '@/api/c_wms_goods'

export default {
  name: 'TableList',
  components: {
    STable,
    CreateForm
  },
  data () {
    return {
      pageNum: 1,
      loading: false,
      confirmLoading: false,
      // 创建窗口控制
      visible: false,
      mdl: {},
      // 查询参数
      queryParam: {},
      // 表头
      columns: [
        {
          title: '序号',
          dataIndex: 'serial',
          width: 50,
          fixed: 'left',
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '货品名称',
          fixed: 'left',
          width: 180,
          dataIndex: 'goods_name',
          ellipsis: true
        },
        {
          title: '条形码',
          align: 'center',
          width: 150,
          dataIndex: 'barcode',
          ellipsis: true
        },
        {
          title: '海关编码',
          align: 'center',
          width: 100,
          dataIndex: 'hs_code',
          ellipsis: true
        },
        {
          title: '售卖单位',
          align: 'center',
          ellipsis: true,
          width: 100,
          dataIndex: 'unit',
          customRender: text => this.$Dictionaries.unit[text] || '无'
        },
        {
          title: '法定单位',
          align: 'center',
          ellipsis: true,
          width: 100,
          dataIndex: 'legal_unit',
          customRender: text => this.$Dictionaries.unit[text] || '无'
        },
        {
          title: '法定单位2',
          align: 'center',
          ellipsis: true,
          width: 100,
          dataIndex: 'legal_unit2',
          customRender: text => this.$Dictionaries.unit[text] || '无'
        },
        {
          title: '售卖单位与法定单位的比例',
          align: 'center',
          width: 200,
          dataIndex: 'rate'
        },
        {
          title: '售卖单位与法定第二单位的比例',
          align: 'center',
          width: 220,
          dataIndex: 'rate2'
        },
        {
          title: '每单位净重(千克)',
          align: 'center',
          width: 150,
          ellipsis: true,
          dataIndex: 'net_weight'
        },
        {
          title: '每单位毛重(千克)',
          align: 'center',
          ellipsis: true,
          width: 150,
          dataIndex: 'gross_weight'
        },
        {
          title: '品牌、规格、型号',
          align: 'center',
          ellipsis: true,
          width: 160,
          dataIndex: 'model'
        },
        {
          title: '增值税（%）',
          align: 'center',
          ellipsis: true,
          width: 100,
          dataIndex: 'value_added_tax_rate'
        },
        {
          title: '原产地',
          align: 'center',
          ellipsis: true,
          width: 100,
          dataIndex: 'original_place',
          customRender: text => this.$Dictionaries.country[text] || '无'
        },
        {
          title: '开启消费税',
          align: 'center',
          width: 100,
          dataIndex: 'open_consumption_tax',
          customRender: text => text === 1 ? '否' : '是'
        },
        {
          title: '消费税单位',
          align: 'center',
          ellipsis: true,
          width: 100,
          dataIndex: 'consumption_tax_unit',
          customRender: text => this.$Dictionaries.consumption_tax_unit[text] || '无'
        },
        {
          title: '操作',
          align: 'center',
          fixed: 'right',
          dataIndex: 'action',
          width: 180,
          scopedSlots: { customRender: 'action' }
        }
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        return goods_declaration_list(Object.assign(parameter, this.queryParam))
          .then(({ data }) => {
            return data
          })
      }
    }
  },
  created () {
    this.setCurrentPage()
    this.queryParam.barcode = this.$route.query.barcode
    if (this.queryParam.barcode) {
      this.mdl.barcode = this.queryParam.barcode
    }
  },
  methods: {
    setCurrentPage () {
      const page = sessionStorage.getItem('CurrentPage')
      if (page) {
        console.log(page)
        const current = page.split('#$$#')[0]
        const query = page.split('#$$#')[1]
        const path = page.split('#$$#')[2]
        sessionStorage.removeItem('CurrentPage')
        if (path === this.$route.path) {
          if (!isNaN(Number(current))) {
            this.pageNum = Number(current)
          }
          if (query) {
            this.queryParam = JSON.parse(query)
          }
        }
      }
    },
    handleAdd () {
      this.mdl = null
      this.visible = true
    },
    handleAddCancel () {
      this.visible = false
      const form = this.$refs.createModal.form
      form.resetFields() // 清理表单数据（可不做）
    },
    handleGoodsDeclaration (record) {
      console.log(record)
      this.$router.push({ path: '/zb/wms/consumption_tax_level', query: { barcode: record.barcode } })
    },
    handleEdit (record) {
      sessionStorage.setItem('CurrentPage', this.$refs.table.getCurrent() + '#$$#' + JSON.stringify(this.queryParam) + '#$$#' + this.$route.path)
      this.$emit('onEdit', record)
    },
    handleDelete (record) {
      console.log(record.id)
      goods_declaration_delete(record.id).then((res) => {
        console.log(res)
        if (res.code === 1000) {
          this.$refs.table.refresh()
        }
      })
    },
    taxLevelSortByMinPrice (obj1, obj2) {
      const mp1 = obj1.min_price
      const mp2 = obj2.min_price
      if (mp1 < mp2) {
        return -1
      } else if (mp1 > mp2) {
        return 1
      } else {
        return 0
      }
    },
    checkTaxLevel (tax_level_list) {
      tax_level_list.sort(this.taxLevelSortByMinPrice)
      console.log('排序后', tax_level_list)
      for (let index = 0; index < tax_level_list.length; index++) {
        const item = tax_level_list[index]
        if (item.min_price >= item.max_price) {
          return false
        }
        if (index > 0) {
          if (tax_level_list[index].min_price < tax_level_list[index - 1].max_price || tax_level_list[index].max_price <= tax_level_list[index - 1].max_price) {
            return false
          }
        }
      }
      return true
    },
    handleOk (tax_rate_info) {
      const form = this.$refs.createModal.form
      form.validateFields((errors, values) => {
        if (!errors) {
          // 新增
          values.value_added_tax_rate = (values.value_added_tax_rate / 100).toFixed(2)
          if (tax_rate_info) {
           if (tax_rate_info.tax_level_list) {
             if (!this.checkTaxLevel(tax_rate_info.tax_level_list)) {
               this.$message.warning('消费税层级有异常，重新编辑后再提交')
               return
             }
             tax_rate_info.tax_level_list.map(item => {
               item.key = undefined
               item.barcode = values.barcode
               item.min_price = Math.round(item.min_price * 100)
               item.max_price = Math.round(item.max_price * 100)
               item.consumption_tax_rate = item.consumption_tax_rate / 100
               return item
             })
           }
          }
          values.open_consumption_tax = values.open_consumption_tax ? 2 : 1
        const request_data = {
            ...values,
          'tax_rate_info': tax_rate_info
        }
          console.log('提交的数据', request_data)
          this.confirmLoading = true
          goods_declaration_create(request_data).then(res => {
              this.visible = false
              this.confirmLoading = false
              // 重置表单数据
              form.resetFields()
            if (res.code === 1000) {
              // 刷新表格
              this.$refs.table.refresh()
            }
          }).catch((err) => {
              console.log(err)
              this.confirmLoading = false
            })
        } else {
          this.confirmLoading = false
        }
      })
    }
  }
}
</script>
