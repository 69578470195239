<template>
  <div>
    <a-card :bordered="false">
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="6" :sm="24">
              <a-form-item label="订单编号">
                <a-input v-model="queryParam.order_no" placeholder="请输入编号" allow-clear @keyup.enter.native="$refs.table.refresh(true)"/>
              </a-form-item>
            </a-col>
            <a-col :md="4" :sm="24">
              <a-form-item label="状态">
                <a-select v-model="queryParam.status" allow-clear placeholder="请选择" default-value="0" @keyup.enter.native="$refs.table.refresh(true)">
                  <a-select-option :key="key" :value="key" v-for="(val, key) in this.$Dictionaries.applet_order_status">{{ val }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="4" :sm="24">
              <a-form-item label="自提优先">
                <a-checkbox v-model="queryParam.distribution" @change="onDeliverFirstChange"/>
              </a-form-item>
            </a-col>
            <a-col :md="2" :sm="24">
              <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
            </a-col>
            <a-col :md="2" :sm="24">
              <a-button type="primary" @click="handle_to_warehouse">转到仓库</a-button>
            </a-col>
            <a-col :md="2" :sm="24">
              <a-button type="primary" @click="handle_to_warehouse_config">订单配置</a-button>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <s-table
        ref="table"
        size="small"
        rowKey="id"
        :rowSelection="options.rowSelection"
        :alert="options.alert"
        :loading="loading"
        :columns="columns"
        :pageNum="pageNum"
        :data="loadData"
      >
        <span slot="serial" slot-scope="text, record, index">
          {{ index + 1 }}
        </span>
        <div slot="price_render" slot-scope="text" style="min-width: 110px">
          {{ (text/100).toFixed(2) }}
        </div>
        <span slot="action" slot-scope="text, record">
          <template>
            <a @click="handleDetail(record)">详情</a>
            <a-divider type="vertical" v-if="record.distribution === 1 && (record.status === 3 || record.status === 4)"/>
            <a @click="handleLogisticsTrajectory(record)" v-if="record.distribution === 1 && (record.status ===3 || record.status ===4)">物流</a>
            <a-divider type="vertical" v-if="record.status === 2 || record.status === 3"/>
            <a @click="handleStatus(record)" v-if="record.status === 2 || record.status === 3">更改状态</a>
<!--            <a-divider type="vertical" v-if="record.status === 3"/>-->
<!--            <a-popconfirm-->
<!--              v-if="record.status === 3"-->
<!--              title="确定改为已收货？"-->
<!--              ok-text="确定"-->
<!--              cancel-text="取消"-->
<!--              @confirm="handleReceived(record)"-->
<!--            >-->
<!--              <a>已收货</a>-->
<!--            </a-popconfirm>-->
          </template>
        </span>
      </s-table>
      <a-modal
        v-model="select_status_visible"
        :confirm-loading="confirm_loading"
        title="审核"
        ok-text="确认"
        cancel-text="取消"
        @ok="select_status">
        <a-select style="width: 280px" @change="handleSelectChange" v-model="select_status_data">
          <a-select-option
            v-for=" (val, key) in this.$Dictionaries.applet_order_exam_status"
            :key="key"
            :value="key">
            {{ val }}
          </a-select-option>
        </a-select>
        <br />
        <br />
      </a-modal>
      <order-circulate-config
        ref="configModal"
        v-if="configVisible"
        :visible="configVisible"
        :loading="confirmLoading"
        :model="mdl"
        :title="title"
        @cancel="handleConfigCancel"
        @ok="handleConfigOk"
      />
      <order-status-form
        ref="orderStatusModal"
        v-if="change_status_visible"
        :visible.sync="change_status_visible"
        :loading="statusConfirmLoading"
        :model="mdl"
        @cancel="handleStatusCancel"
        @ok="handleStatusOk" />
      <order-logistics-trajectory-form
        ref="orderLogisticsTrajectoryModal"
        v-if="logisticsTrajectoryVisible"
        :visible.sync="logisticsTrajectoryVisible"
        :loading="logisticsTrajectoryLoading"
        :model="mdl"
        @cancel="handleCancelLogisticsTrajectory"
      />
    </a-card>
  </div>
</template>

<script>
import { STable } from '@/components'
import {
  orderToExamine,
  orderList,
  putReceived,
  orderToWarehouse,
  putOrderReceived, orderToWarehouseConfig, putOrderToWarehouseConfig, putOrderStatus
} from '@/api/applet_shopmall_order'
import OrderCirculateConfig from '@/views/a-applet/shop_manage/OrderCirculateConfig'
import OrderStatusForm from '@/views/a-applet/shop_manage/OrderStatusForm'
import OrderLogisticsTrajectoryForm from '@/views/a-applet/shop_manage/OrderLogisticsTrajectoryForm'
export default {
  name: 'TableList',
  components: {
    STable,
    OrderCirculateConfig,
    OrderStatusForm,
    OrderLogisticsTrajectoryForm
  },
  data () {
    return {
      title: '自动转到仓库订单',
      pageNum: 1,
      select_status_visible: false,
      confirm_loading: false,
      change_status_visible: false,
      statusConfirmLoading: false,
      logisticsTrajectoryVisible: false,
      logisticsTrajectoryLoading: false,
      select_status_data: '3',
      refund: 0,
      refund_reason: '',
      loading: false,
      confirmLoading: false,
      order_id: 0,
      // 创建窗口控制
      visible: false,
      configVisible: false,
      mdl: {},
      // 查询参数
      queryParam: {},
      selectedRowKeys: [],
      selectedRows: [],

      // custom table alert & rowSelection
      options: {
        alert: {
          show: true,
          clear: () => {
            this.selectedRowKeys = []
          }
        },
        rowSelection: {
          getCheckboxProps: record => ({
            props: {
              disabled: (record.status !== 2)
            }
          }),
          selectedRowKeys: this.selectedRowKeys,
          onChange: this.onSelectChange
        }
      },
      optionAlertShow: false,
      // 表头
      columns: [
        {
          title: '序号',
          width: '50px',
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '订单编号',
          ellipsis: true,
          dataIndex: 'order_no',
          width: 200
        },
        {
          title: '收货人',
          dataIndex: 'receiving_name'
        },
        {
          title: '电话',
          ellipsis: true,
          dataIndex: 'receiving_phone'
        },
        {
          title: '支付金额',
          dataIndex: 'actual_paid',
          align: 'center',
          scopedSlots: { customRender: 'price_render' }
        },
        {
          title: '模式',
          dataIndex: 'distribution',
          width: 100,
          customRender: (text) => this.$Dictionaries.delivery_method[text] || '无'
        },
        {
          title: '订单状态',
          dataIndex: 'status',
          align: 'center',
          width: 150,
          customRender: (text) => this.$Dictionaries.applet_order_status[text] || '无'
        },
        {
          title: '下单时间',
          width: 150,
          align: 'center',
          dataIndex: 'create_time',
          customRender: (text) => (text === '1970-01-01 00:00:00' && '--') || text
        },
        {
          title: '支付时间',
          dataIndex: 'pay_time',
          width: 150,
          align: 'center',
          customRender: (text) => (text === '1970-01-01 00:00:00' && '--') || text
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: 180,
          scopedSlots: { customRender: 'action' }
        }
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        return orderList(Object.assign(parameter, this.queryParam))
          .then((res) => {
            console.log('订单管理：', res)
            return res.data
          })
      }
    }
  },
  created () {
    this.tableOption()
    this.setCurrentPage()
  },
  methods: {
    setCurrentPage () {
      // const page = sessionStorage.getItem('CurrentPage')
      const page = this.$store.getters.pop
      if (page) {
        console.log(page)
        const current = page.split('#$$#')[0]
        const query = page.split('#$$#')[1]
        const path = page.split('#$$#')[2]
        sessionStorage.removeItem('CurrentPage')
        if (path === this.$route.path) {
          if (!isNaN(Number(current))) {
            this.pageNum = Number(current)
          }
          if (query) {
            this.queryParam = JSON.parse(query)
          }
        } else {
          this.queryParam = {}
          this.$store.dispatch('clear')
        }
      }
    },
    tableOption () {
      if (!this.optionAlertShow) {
        this.options = {
          alert: {
            show: true,
            clear: () => {
              this.selectedRowKeys = []
            }
          },
          rowSelection: {
            getCheckboxProps: record => ({
              props: {
                disabled: (record.status !== 2) || (record.is_sync)
              }
            }),
            selectedRowKeys: this.selectedRowKeys,
            onChange: this.onSelectChange
          }
        }
        this.optionAlertShow = true
      } else {
        this.options = {
          alert: false,
          rowSelection: null
        }
        this.optionAlertShow = false
      }
    },
    handleDetail (record) {
      sessionStorage.setItem('CurrentPage', this.$refs.table.getCurrent() + '#$$#' + JSON.stringify(this.queryParam) + '#$$#' + this.$route.path)
      console.log(record.id)
      this.$router.push({ path: 'order/detail', query: { id: record.id, status: record.status } })
    },
  //  手动补订单库存数量
    onDeliverFirstChange (e) {
      console.log(e)
      if (!e.target.checked) {
        this.queryParam.distribution = undefined
      } else {
        this.queryParam.distribution = 2
      }
      this.$refs.table.refresh(true)
    },
    select_status () {
      if (this.select_status_data === '请选择') {
        this.$message.warning('请选择')
        return
      }
      const param = {
        status: this.select_status_data,
        order_id: this.order_id
      }
      if (this.select_status_data === '5') {
        param.refund = this.refund
      }
      this.confirm_loading = true
      orderToExamine(param).then(({ data }) => {
        console.log(data)
        this.select_status_data = '请选择'
        this.refund = 0
        this.order_id = 0
        this.select_status_visible = false
        this.confirm_loading = false
        this.$refs.table.refresh(true)
      })
    },
    // 订单审核
    handle_to_warehouse () {
      if (this.selectedRows && this.selectedRows.length > 0) {
        const filterRows = this.selectedRows.filter(item => {
          return item.status === 2
        })
        if (filterRows.length !== this.selectedRows.length) {
          this.$message.warning('选择已支付未发货订单')
        } else {
          const data = {
            order_ids: this.selectedRowKeys
          }
          orderToWarehouse(data).then(res => {
            if (res.code === 1000) {
              this.$message.warning('操作成功')
              this.selectedRowKeys = []
              this.selectedRows = []
              this.$refs.table.refresh(true)
            }
          })
        }
      } else {
        this.$message.warning('选择订单')
      }
    },
    // 订单退款
    handleStatus (record) {
      this.change_status_visible = true
      this.mdl = record
      // // // 2 审核通过，5 审核不通过
      // // this.order_id = record.id
      // // sessionStorage.setItem('CurrentPage', this.$refs.table.getCurrent() + '#$$#' + JSON.stringify(this.queryParam) + '#$$#' + this.$route.path)
      // this.$store.dispatch('push', this.$refs.table.getCurrent() + '#$$#' + JSON.stringify(this.queryParam) + '#$$#' + this.$route.path)
      // this.$router.push({ path: 'order/detail_refund', query: { id: record.id } })
      // sessionStorage.setItem('CurrentPage', this.$refs.table.getCurrent() + '#$$#' + JSON.stringify(this.queryParam) + '#$$#' + this.$route.path)
      // this.$router.push({ path: 'order/detail_refund', query: { id: record.id, status: record.status } })
    },
    handleStatusCancel () {
      this.change_status_visible = false
    },
    handleStatusOk () {
      const form = this.$refs.orderStatusModal.form
      this.statusConfirmLoading = true
      form.validateFields((errors, values) => {
        if (!errors) {
          // 新增
          putOrderStatus(values['status'], this.mdl.id).then(res => {
            // 刷新表格
            if (res.code === 1000) {
              this.$refs.table.refresh()
            }
          }).finally(() => {
            this.change_status_visible = false
            this.statusConfirmLoading = false
            form.resetFields()
          })
        } else {
          this.statusConfirmLoading = false
        }
      })
    },
    handleSelectChange (value) {
      this.select_status_data = value
      console.log(this.select_status_data, typeof this.select_status_data)
    },
    handleReceived (value) {
      putOrderReceived(value.id).then(data => {
        console.log(data)
        if (data) {
            if (data.code === 1000) {
              this.$refs.table.refresh(true)
            } else {
              if (data.msg) {
                this.$message.warning(data.msg)
              }
            }
        }
      })
    },
    handleAgreeRefund (value) {
      putReceived({ order_no: value.order_no }).then(data => {
        console.log(data)
        if (data) {
            if (data.code === 1000) {
              this.$refs.table.refresh(true)
            } else {
              if (data.msg) {
                this.$message.warning(data.msg)
              }
            }
        }
      })
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
    handleConfigCancel () {
      this.configVisible = false
      this.confirmLoading = false
    },
    handleConfigOk () {
      const form = this.$refs.configModal.form
      this.confirmLoading = true
      form.validateFields((errors, values) => {
        if (!errors) {
          putOrderToWarehouseConfig(values, this.mdl.id).then(res => {
            if (res.code === 1000) {
              this.handleConfigCancel()
            } else {
              this.$message.warning(res.msg)
            }
          })
        } else {
          this.confirmLoading = false
        }
      })
    },
    handle_to_warehouse_config () {
      orderToWarehouseConfig({ type: 1 }).then(res => {
        console.log(res)
        if (res.code === 1000 && res.data.entries.length > 0) {
          this.mdl = res.data.entries[0]
          this.configVisible = true
        } else {
          this.$message.warning(res.msg)
        }
      })
    },
    handleLogisticsTrajectory (record) {
      this.mdl = record
      this.logisticsTrajectoryVisible = true
    },
    handleCancelLogisticsTrajectory () {
      this.logisticsTrajectoryVisible = false
    }
  }
}
</script>
