<template>
  <a-modal
    title="新建"
    :width="1080"
    :visible="visible"
    :confirmLoading="loading"
    @ok="() => { $emit('ok', this.date) }"
    @cancel="() => { $emit('cancel') }"
  >
    <a-spin :spinning="loading">
      <a-form :form="form" v-bind="formLayout">
        <a-row :gutter="[16,16]">
          <a-col :span="24">
            <a-form-item label="编号">
              <a-input v-decorator="['number', {rules: [{required: true, message: '必填项，请填写信息'}, {max:32, message: '输入超出长度限制'}]}]" />
            </a-form-item>
            <a-form-item label="主体企业">
              <a-select
                option-label-prop="tt"
                :show-arrow="false"
                :filter-option="false"
                :not-found-content="null"
                show-search
                allow-clear
                v-decorator="[
                  'host_corporation_id',
                  {
                    rules: [
                      { required: true, message: '必填项，请输入主体企业' },
                    ],
                  },
                ]"
                placeholder="请输入主体企业"
                :default-active-first-option="false"
                @search="this.corporationSearch"
                @change="handleCorporationChange"
              >
                <a-select-option v-for="d in corporationList" :key="d.id" :tt="d.name">
                  {{ d.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="交易品种">
              <a-select
                option-label-prop="tt"
                :show-arrow="false"
                :filter-option="false"
                :not-found-content="null"
                show-search
                allow-clear
                v-decorator="[
                  'category_id',
                  {
                    rules: [
                      { required: true, message: '必填项，请输入交易品种' },
                    ],
                  },
                ]"
                placeholder="请输入交易品种"
                :default-active-first-option="false"
                @search="this.categorySearch"
                @change="handleCategoryChange"
              >
                <a-select-option v-for="d in categoryList" :key="d.id" :tt="d.name">
                  {{ d.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="名称">
              <a-input v-decorator="['name', {rules: [{required: true, message: '必填项，请填写信息'}, {max:128, message: '输入超出长度限制'}]}]" />
            </a-form-item>
            <a-form-item label="信用">
              <a-input-number :precision="2" style="width: 50%" v-decorator="['credit', {rules: [{required: true, message: '必填项，请填写信息'}]}]" />
            </a-form-item>
            <a-form-item label="现金">
              <a-input-number :precision="2" style="width: 50%" v-decorator="['cash', {rules: [{required: true, message: '必填项，请填写信息'}]}]" />
            </a-form-item>
            <a-form-item label="起止时间">
              <a-range-picker @change="onDateChange" v-decorator="['date', {rules: [{required: true, message: '必填项，请填写信息'}]}]"/>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>
import pick from 'lodash.pick'
import debounce from 'lodash/debounce'
import { corporation_list } from '@/api/trade_corporation'
import { category_list } from '@/api/trade_category'

// 表单字段
const fields = ['id', 'host_corporation_id', 'category_id', 'number', 'name', 'credit', 'cash']
export default {
  components: {},
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
      default: () => false
    },
    model: {
      type: Object,
      default: () => null
    }
  },
  data () {
    this.formLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 7 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 13 }
      }
    }
    this.corporationSearch = debounce(this.handleCorporationSearch, 800)
    this.categorySearch = debounce(this.handleCategorySearch, 800)
    return {
      corporationList: [],
      categoryList: [],
      form: this.$form.createForm(this),
      date: {
        start_date: '',
        end_date: ''
      }
    }
  },
  created () {
    console.log('custom modal created', this.form)
    // 防止表单未注册
    fields.forEach(v => this.form.getFieldDecorator(v))
    const date = new Date()
    const number = { number: date.getFullYear() + '' + (date.getMonth() + 1) + date.getDate() }
    const data = { ...this.model, ...number }
    this.model && this.form.setFieldsValue(pick(data, fields))
    this.handleCorporationSearch(undefined)
    this.handleCategorySearch(undefined)
  },
  methods: {
    handleCorporationSearch (value) {
      corporation_list({ name: value }).then((res) => {
        const result = res.data.entries || []
        this.corporationList = !value ? (result.length > 5 ? result.slice(0, 5) : result) : result
      })
    },
    handleCategorySearch (value) {
      category_list({ name: value }).then((res) => {
        const result = res.data.entries || []
        this.categoryList = !value ? (result.length > 5 ? result.slice(0, 5) : result) : result
      })
    },
    handleCorporationChange (value) {
      console.log(value)
      if (value === undefined) {
        this.handleCorporationSearch(value)
      }
    },
    handleCategoryChange (value) {
      console.log(value)
      if (value === undefined) {
        this.handleCategorySearch(value)
      }
    },
    onDateChange (date, dateString) {
      console.log(date, dateString)
      this.date.start_date = dateString[0]
      this.date.end_date = dateString[1]
    }
  }
}
</script>
