<template>
  <div>
    <a-card :bordered="false">
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="6" :sm="24">
              <a-form-item label="订单编号">
                <a-input
                  v-model="queryParam.order_no"
                  placeholder="请输入编号"
                  allow-clear
                  @keyup.enter.native="$refs.table.refresh(true)" />
              </a-form-item>
            </a-col>
            <a-col :md="2" :sm="24">
              <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <s-table
        ref="table"
        size="small"
        rowKey="id"
        :loading="loading"
        :columns="columns"
        :data="loadData"
      >
        <span slot="serial" slot-scope="text, record, index">
          {{ index + 1 }}
        </span>
        <div slot="price_render" slot-scope="text" style="min-width: 110px">
          {{ (text / 100).toFixed(2) }}
        </div>
        <span slot="action" slot-scope="text, record">
          <template>
            <a @click="handleRefundGoodsList(record)">商品列表</a>
            <a-divider type="vertical" v-if="record.status === 1"/>
            <a v-if="record.status === 1" @click="handleAgreeRefund(record)">审批</a>
            <a-divider type="vertical" v-if="record.status === 3 && record.need_return_goods"/>
            <a v-if="record.status === 3 && record.need_return_goods" @click="handle_to_examine(record)">审核商品</a>
            <a-divider type="vertical" v-if="record.status === 2"/>
            <a v-if="record.status === 2" @click="handle_to_refund(record)">退款</a>
          </template>
        </span>
      </s-table>
      <examine-form
        v-if="select_status_visible"
        ref="createModal"
        :visible="select_status_visible"
        :loading="confirmLoading"
        :model="mdl"
        @cancel="handleAddCancel"
        @ok="handleOk"
      />
      <a-modal
        v-if="refund_status_visible"
        v-model="refund_status_visible"
        title="退款审核"
        ok-text="确认"
        cancel-text="取消"
        @ok="selectAgreeRefund">
        <a-row :gutter="10">
          <a-col :md="24" :sm="24">
            <a-form-item label="同意退款" :labelCol="labelCol" :wrapperCol="wrapperCol">
              <a-radio-group v-model="agreeRefund">
                <a-radio :value="true">
                  是
                </a-radio>
                <a-radio :value="false">
                  否
                </a-radio>
              </a-radio-group>
            </a-form-item>
          </a-col>
          <a-col :md="24" :sm="24" v-if="agreeRefund">
            <a-form-item label="退还商品" :labelCol="labelCol" :wrapperCol="wrapperCol">
              <a-radio-group v-model="needReturnGoods">
                <a-radio :value="true">
                  是
                </a-radio>
                <a-radio :value="false">
                  否
                </a-radio>
              </a-radio-group>
            </a-form-item>
          </a-col>
        </a-row>
      </a-modal>
      <a-modal
        v-model="refund_goods_visible"
        title="退款"
        ok-text="确认"
        cancel-text="取消"
        @ok="toRefund">
        <a-row :gutter="1">
          <a-col :md="24" :sm="24">
            <a-form-item label="退款金额" :labelCol="labelCol" :wrapperCol="wrapperCol">
              <a-input-number
                v-model="refund"
                style="width: 100%"
                v-decorator="['refund', {rules: [{ required: true, message: '请输入' }]}]" />
            </a-form-item>
          </a-col>
          <a-col :md="12" :sm="24" v-if="false">
            <a-form-item label="理由">
              <a-input
                v-model="refund_reason"
                placeholder="请输入理由"
              />
            </a-form-item>
          </a-col>
        </a-row>
      </a-modal>
    </a-card>
  </div>
</template>

<script>
import { STable } from '@/components'
import { refundList, refundExamine, createRefundGoods, putReceived } from '@/api/applet_shopmall_order'
import ExamineForm from '@/views/a-applet/shop_manage/refund/modules/ExamineForm'

export default {
  name: 'TableList',
  components: {
    ExamineForm,
    STable
  },
  data () {
    return {
      labelCol: {
        xs: { span: 6 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 12 }
      },
      tag_value: '',
      dataSource_tag: [],
      tags: [],
      allTags: [],
      selectedTags: [],
      select_status_visible: false,
      refund_status_visible: false,
      refund_goods_visible: false,
      select_status_data: '请选择',
      agreeRefund: true,
      needReturnGoods: true,
      refund: 0,
      refund_reason: '',
      loading: false,
      confirmLoading: false,
      refund_id: 0,
      // 创建窗口控制
      visible: false,
      mdl: {},
      payconfirmLoading: false,
      // 创建窗口控制
      payvisible: false,
      paymdl: {},
      // 高级搜索 展开/关闭
      advanced: false,
      // 查询参数
      queryParam: {},
      // 表头
      columns: [
        {
          title: '序号',
          width: '50px',
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '订单编号',
          ellipsis: true,
          dataIndex: 'order_no',
          width: 200
        },
        {
          title: '退款编号',
          dataIndex: 'refund_no',
          ellipsis: true,
          width: 200
        },
        {
          title: '退货运单号',
          dataIndex: 'waybill_no',
          ellipsis: true
        },
        {
          title: '退款金额',
          dataIndex: 'price',
          align: 'center',
          scopedSlots: { customRender: 'price_render' }
        },
        {
          title: '原因',
          dataIndex: 'reason',
          ellipsis: true
        },
        {
          title: '状态',
          dataIndex: 'status',
          ellipsis: true,
          customRender: (text) => this.$Dictionaries.refund_order_status[text] || '无'
        },
        {
          title: '审批人',
          dataIndex: 'allow_by',
          ellipsis: true
        },
        {
          title: '操作',
          dataIndex: 'action',
          scopedSlots: { customRender: 'action' }
        }
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        return refundList(Object.assign(parameter, this.queryParam))
          .then((res) => {
            console.log('订单管理：', res)
            return res.data
          })
      }
    }
  },
  methods: {
    handleDetail (record) {
      console.log(record.id)
      this.$router.push({ path: 'order/detail', query: { id: record.id } })
    },
    //  手动补订单库存数量
    onDeliverFirstChange (e) {
      console.log(e)
      if (!e.target.checked) {
        this.queryParam.self_delivery_first = undefined
      }
      this.$refs.table.refresh(true)
    },
    selectAgreeRefund () {
      const param = {
        status: this.agreeRefund ? 2 : 5,
        need_return_goods: this.needReturnGoods ? 1 : 0,
        refund_id: this.refund_id
      }
      putReceived(param).then(data => {
        if (data) {
          if (data.code === 1000) {
              this.refund_id = 0
              this.agreeRefund = true
              this.needReturnGoods = true
              this.refund_status_visible = false
            this.$refs.table.refresh(true)
          } else {
            if (data.msg) {
              this.$message.warning(data.msg)
            }
          }
        }
      })
    },
    handle_to_refundarea (record) {
      const param = {
        // refund: this.refund * 100,
        refund_id: record.id
      }

      createRefundGoods(param, this.refund_id).then(({ data }) => {
        console.log(data)
        this.refund = 0
        this.refund_goods_visible = false
        this.$refs.table.refresh(true)
      })
    },
    toRefund () {
      const param = {
        refund: this.refund * 100,
        refund_id: this.refund_id,
        status: 4
      }
      if (this.refund_reason !== undefined && this.refund_reason.length > 0) {
        param.reason = this.refund_reason
      } else {
        this.$message.warning('请输入退款理由')
        return
      }
      putReceived(param).then(({ data }) => {
        console.log(data)
        this.refund = 0
        this.refund_id = 0
        this.refund_reason = undefined
        this.refund_goods_visible = false
        this.$refs.table.refresh(true)
      })
    },
    // 订单审核
    handle_to_examine (record) {
      this.select_status_visible = true
      // 2 审核通过，5 审核不通过
      this.refund_id = record.id
    },
    // 退款
    handle_to_refund (record) {
      this.refund_goods_visible = true
      this.refund = (record.price / 100).toFixed(2)
      this.refund_reason = record.reason
      // 2 审核通过，5 审核不通过
      this.refund_id = record.id
    },
    // 检验完整性
    handleAgreeRefund (record) {
      this.refund_status_visible = true
      // 2 审核通过，5 审核不通过
      this.refund_id = record.id
    },
    handleRefundGoodsList (record) {
      this.$router.push({
        path: '/appletshop/refund/goods',
        query: {
          refund_no: record.refund_no
        }
      })
    },
    handleSelectChange (value) {
      this.select_status_data = value
      console.log(this.select_status_data, typeof this.select_status_data)
    },
    handleAddCancel () {
      this.select_status_visible = false
    },
    handleOk () {
      const form = this.$refs.createModal.form
      this.confirmLoading = true
      form.validateFields((errors, values) => {
        if (!errors) {
          console.log('values', values)
          // 新增
          values.refund_id = this.refund_id
          refundExamine(values).then(res => {
            this.visible = false
            this.confirmLoading = false
            // 重置表单数据
            form.resetFields()
            // 刷新表格
            this.$refs.table.refresh()
          })
            .catch((e) => {
              console.log(e)
            })
            .finally(() => {
              this.confirmLoading = false
              this.select_status_visible = false
            })
        } else {
          this.select_status_visible = false
          this.confirmLoading = false
        }
      })
    }
  }
}
</script>
