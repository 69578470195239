<template>
  <div>
    <a-card :bordered="false">
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="4" :sm="24">
              <a-form-item label="批次">
                <a-input v-model="queryParam.batch" placeholder="请输入批次" allow-clear @keyup.enter.native="$refs.table.refresh(true)"/>
              </a-form-item>
            </a-col>
            <a-col :md="4" :sm="24">
              <a-form-item label="卡号">
                <a-input v-model="queryParam.card_no" placeholder="请输入卡号" allow-clear @keyup.enter.native="$refs.table.refresh(true)"/>
              </a-form-item>
            </a-col>
            <a-col :md="4" :sm="24">
              <a-form-item label="状态">
                <a-select v-model="queryParam.status" allow-clear placeholder="请选择">
                  <a-select-option :key="key" :value="key" v-for="(val, key) in this.$Dictionaries.value_card_status">{{ val }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="4" :sm="24">
              <div class="table-operator">
                <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
              </div>
            </a-col>
            <a-col :md="2" :sm="24">
              <div class="table-operator">
                <a-button type="primary" icon="export" @click="handleExport">导出</a-button>
              </div>
            </a-col>
            <a-col :md="2" :sm="24">
              <div class="table-operator">
                <a-button type="primary" icon="plus" @click="handleCreate">添加</a-button>
              </div>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <s-table
        ref="table"
        size="small"
        rowKey="id"
        :loading="loading"
        :columns="columns"
        :data="loadData"
      >
        <span slot="serial" slot-scope="text, record, index">
          {{ index + 1 }}
        </span>
        <div slot="price_render" slot-scope="text" style="text-align: left; min-width: 90px; max-width: 240px">
          {{ (text / 100).toFixed(2) }}
        </div>
        <span slot="action" slot-scope="text, record">
          <template>
            <a @click="handleValueCardRecord(record)">消费记录</a>
            <a-divider type="vertical"/>
            <a @click="handleValueCardStatus(record)">修改状态</a>
            <a-divider type="vertical"/>
            <a-popconfirm
              title="确定要删除么？"
              ok-text="确定"
              cancel-text="取消"
              @confirm="handleDelete(record)"
            >
              <a>删除</a>
            </a-popconfirm>
          </template>
        </span>
      </s-table>
    </a-card>
    <create-value-card-form
      ref="createModal"
      v-if="visible"
      :visible.sync="visible"
      :loading="confirmLoading"
      :model="mdl"
      @cancel="handleAddCancel"
      @ok="handleOk"
    />
    <export-value-card-form
      ref="exportModal"
      v-if="exportVisible"
      :visible.sync="exportVisible"
      :loading="confirmLoading"
      :model="mdl"
      @cancel="handleExportCancel"
      @ok="handleExportOK"
    />
    <value-card-consume-record-list
      ref="valueCardModal"
      v-if="value_card_visible"
      :visible.sync="value_card_visible"
      :loading="confirmLoading"
      :model="mdl"
      @cancel="handleValueCardCancel"
    />
    <status-value-card-form
      ref="statusModal"
      v-if="statusVisible"
      :visible.sync="statusVisible"
      :loading="confirmLoading"
      :model="mdl"
      @cancel="handleStatusCancel"
      @ok="handleStatusOK"
    />
  </div>
</template>

<script>
import { STable } from '@/components'
import { createValueCard, deleteValueCard, putValueCard, valueCardExport, valueCardList } from '@/api/shop_value_card'
import CreateValueCardForm from '@/views/a-applet/shop_manage/value_card/CreateValueCardForm'
import ExportValueCardForm from '@/views/a-applet/shop_manage/value_card/ExportValueCardForm'
import ValueCardConsumeRecordList from '@/views/a-applet/shop_manage/value_card/ValueCardConsumeRecordList'
import StatusValueCardForm from '@/views/a-applet/shop_manage/value_card/StatusValueCardForm'

export default {
  name: 'TableList',
  components: {
    STable,
    CreateValueCardForm,
    ExportValueCardForm,
    ValueCardConsumeRecordList,
    StatusValueCardForm
  },
  data () {
    return {
      loading: false,
      visible: false,
      exportVisible: false,
      statusVisible: false,
      confirmLoading: false,
      value_card_visible: false,
      mdl: {},
      // 查询参数
      queryParam: {},
      // 表头
      columns: [
        {
          title: '序号',
          width: '50px',
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '批次',
          ellipsis: true,
          dataIndex: 'batch'
        },
        {
          title: '卡号',
          ellipsis: true,
          dataIndex: 'card_no'
        },
        {
          title: '密码',
          ellipsis: true,
          dataIndex: 'password'
        },
        {
          title: '金额',
          ellipsis: true,
          dataIndex: 'value',
          scopedSlots: { customRender: 'price_render' }
        },
        {
          title: '状态',
          ellipsis: true,
          dataIndex: 'status',
          customRender: (text) => this.$Dictionaries.value_card_status[text] || '无'
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: '190px',
          scopedSlots: { customRender: 'action' }
        }
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        return valueCardList(Object.assign(parameter, this.queryParam))
          .then(({ data }) => {
            return data
          })
      }
    }
  },
  methods: {
    handleCreate () {
      this.mdl = null
      this.visible = true
      this.confirmLoading = false
      // this.$router.push({ name: 'CreateDeliveryAddress' })
    },
    handleExport () {
      this.exportVisible = true
    },
    handleExportOK () {
      const form = this.$refs.exportModal.form
      form.validateFields((errors, values) => {
        if (!errors) {
          valueCardList(values).then(res => {
            if (res.code === 1000) {
              if (res.data.entries.length === 0) {
                this.$message.warning('批次不存在')
                return
              }
              valueCardExport(values).then((res) => {
                const link = document.createElement('a')
                const blob = new Blob([res], { type: 'application/octet-stream' })
                console.log(blob)
                link.style.display = 'none'
                link.href = URL.createObjectURL(blob)
                link.setAttribute('download', `储值卡.xlsx`)
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
              }).finally(() => {
                this.exportVisible = false
                this.confirmLoading = false
              })
            }
          })
        } else {
          this.confirmLoading = false
        }
      })
    },
    handleAddCancel () {
      this.visible = false
      const form = this.$refs.createModal.form
      form.resetFields() // 清理表单数据（可不做）
    },
    handleExportCancel () {
      this.exportVisible = false
      const form = this.$refs.exportModal.form
      form.resetFields() // 清理表单数据（可不做）
    },
    handleOk () {
      const form = this.$refs.createModal.form
      form.validateFields((errors, values) => {
        if (!errors) {
          const end = String(values.card_no_end)
          const start = String(values.card_no_start)
          if (end.length !== start.length) {
            this.$message.warning('卡号长度不一致')
            return
          }
          const equalIndex = this.countCommonStart(start, end)
          const count = Number(end.substring(equalIndex)) - Number(start.substring(equalIndex))
          if (values.count < 0 || count < 0) {
            this.$message.warning('开始卡号大于结束卡号')
            return
          }
          values.equal_index = equalIndex
          values.value = values.value * 100
          this.confirmLoading = true
          createValueCard(values).then((res) => {
            this.visible = false
            this.confirmLoading = false
            this.$refs.table.refresh()
          }).finally(() => {
            this.confirmLoading = false
          })
        } else {
          this.confirmLoading = false
        }
      })
    },
    countCommonStart (str1, str2) {
      const length = Math.min(str1.length, str2.length)
      for (let i = 0; i < length; i++) {
        if (str1[i] !== str2[i]) {
          return i
        }
      }
      return length
    },
    handleValueCardRecord (record) {
      this.mdl = record
      this.value_card_visible = true
    },
    handleValueCardCancel () {
      this.value_card_visible = false
    },
    handleStatusCancel () {
      this.statusVisible = false
    },
    handleValueCardStatus (record) {
      this.mdl = record
      this.statusVisible = true
    },
    handleStatusOK () {
      const form = this.$refs.statusModal.form
      form.validateFields((errors, values) => {
        if (!errors) {
          if (values.status <= this.mdl.status) {
            this.$message.warning('状态修改错误')
            return
          }
          this.confirmLoading = true
          putValueCard(values, this.mdl.id).then((res) => {
            this.visible = false
            this.confirmLoading = false
            if (res.code === 1000) {
              this.$refs.table.refresh()
            }
          }).finally(() => {
            this.confirmLoading = false
            this.statusVisible = false
          })
        } else {
          this.confirmLoading = false
        }
      })
    },
    handleValueCard (item) {
      console.log(item)
      // this.mdl = item
      this.$router.push({
        path: '/commonshop/value_card_list',
        query: {
          id: item.id
        }
      })
    },
    handleDelete (item) {
      deleteValueCard(item.id).then(res => {
        if (res.code === 1000) {
          this.$refs.table.refresh()
        }
      })
    }
  }
}
</script>
