<template>
  <a-modal
    title="订单物流轨迹"
    :width="680"
    :visible="visible"
    :footer="null"
    @ok="() => { $emit('ok') }"
    @cancel="() => { $emit('cancel') }"
  >
    <a-spin :spinning="loading" tip="加载中...">
      <a-timeline v-if="data" style="height: 500px; overflow-y: scroll">
        <a-timeline-item :color="index=== 0 ? 'red': 'green'" v-for="(item, index) in data.list" :key="item.item">
          <div>
            <p>{{ item.upload_Time }}</p>
            <p>{{ item.processInfo }}</p>
          </div>
        </a-timeline-item>
      </a-timeline>
      <a-result v-else title="暂无数据" sub-title="暂时查不到订单的物流轨迹，请稍后再试">
      </a-result>
    </a-spin>
  </a-modal>
</template>
<script>
import { orderLogisticsTrajectory } from '@/api/applet_shopmall_order'

export default {
  name: 'OrderLogisticsTrajectoryForm',
  components: {
  },
  props: {
    model: {
      type: Object,
      default: () => ({})
    },
    visible: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      labelCol: {
        xs: { span: 6 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 17 }
      },
      buttonCol: {
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 12, offset: 5 }
        }
      },
      form: this.$form.createForm(this),
      data: {},
      loading: false
    }
  },
  created () {
    this.loadOrderLogisticsTrajectory()
  },
  filters: {
    deliveryStatus (infoContent) {
      /* GOT 已揽收;ARRIVAL 已收入;DEPARTURE 已发出;SENT_SCAN 派件;INBOUND 自提柜入柜;SIGNED 签收成功;FAILED 签收失败;FORWARDING 转寄;TMS_RETURN 退回; AIRSEND 航空发货;AIRPICK 航空提货  */
      let msg = ''
      switch (infoContent) {
        case 'GOT':
          msg = '已揽收'
          break
        case 'ARRIVAL':
          msg = '已收入'
          break
        case 'DEPARTURE':
          msg = '已发出'
          break
        case 'SENT_SCAN':
          msg = '派件'
          break
        case 'INBOUND':
          msg = '自提柜入柜'
          break
        case 'SIGNED':
          msg = '签收成功'
          break
        case 'FAILED':
          msg = '签收失败'
          break
        case 'FORWARDING':
          msg = '转寄'
          break
        case 'TMS_RETURN':
          msg = '退回'
          break
        case 'AIRSEND':
          msg = '航空发货'
          break
        case 'AIRPICK':
          msg = '航空提货'
      }
      return msg
    }
  },
  methods: {
    loadOrderLogisticsTrajectory () {
      this.loading = true
      orderLogisticsTrajectory({ order_no: this.model.order_no }).then(res => {
        console.log(res)
        if (res.code === 1000 && res.data !== undefined) {
          this.data = res.data
          if (typeof this.data === 'object' && !res.data.success) {
            this.data = undefined
          } else {
            if (this.data && this.data.list && this.data.list.length > 0) {
              this.data.list.sort((a, b) => new Date(b.upload_Time) - new Date(a.upload_Time))
            }
          }
        } else {
          this.data = undefined
        }
      }).finally(() => {
        this.loading = false
      })
    }
  }
}
</script>
