<template>
  <a-modal
    title="编辑地址"
    :width="1080"
    :visible="visible"
    :confirmLoading="loading"
    @ok="() => { $emit('ok') }"
    @cancel="() => { $emit('cancel') }"
  >
    <a-spin :spinning="loading">
      <a-form :form="form" v-bind="formLayout" labelAlign="left">
        <a-row :gutter="[16,16]">
          <a-col :span="12">
            <a-form-item label="收件人" :labelCol="formLayout.labelCol" :wrapperCol="formLayout.wrapperCol">
              <a-input v-decorator="['receiving_name', {rules: [{required: true, message: '请输入'}, {max:24, message: '输入不符合长度限制'}]}]" />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="电话" :labelCol="formLayout.labelCol" :wrapperCol="formLayout.wrapperCol">
              <a-input v-decorator="['receiving_phone', {rules: [{required: true, message: '请输入'}, {max:20, message: '输入不符合长度限制'}]}]" />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="省">
              <a-input v-decorator="['province', {rules: [{required: true, message: '请输入'}, {max:40, message: '输入不符合长度限制'}]}]" />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="市">
              <a-input v-decorator="['city', {rules: [{required: true, message: '请输入'}, {max:40, message: '输入不符合长度限制'}]}]" />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="区">
              <a-input v-decorator="['county', {rules: [{required: true, message: '请输入'}, {max:40, message: '输入不符合长度限制'}]}]" />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="详细地址">
              <a-input v-decorator="['address', {rules: [{required: true, message: '请输入'}, {max:100, message: '输入不符合长度限制'}]}]" />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="默认地址">
              <a-switch v-decorator="['is_default', { valuePropName: 'checked' }]" />
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>
import pick from 'lodash.pick'
// 表单字段
const fields = ['receiving_name', 'receiving_phone', 'province', 'city', 'county', 'address', 'is_default']
export default {
  name: 'UpdateAddressModal',
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
      default: () => false
    },
    model: {
      type: Object,
      default: () => null
    }
  },
  data () {
    return {
      formLayout: {
        labelCol: {
          xs: { span: 8 },
          sm: { span: 4 }
        },
        wrapperCol: {
          xs: { span: 16 },
          sm: { span: 16 }
        }
      },
      form: this.$form.createForm(this)
    }
  },
  created () {
    fields.forEach(v => this.form.getFieldDecorator(v))
    this.get(this.model)
  },
  methods: {
    get (model) {
      const formData = pick(model, fields)
      console.log('formData', formData)
      this.form.setFieldsValue(formData)
    },
    displayRender ({ labels }) {
      return labels[labels.length - 1]
    }
  }
}
</script>
