<template>
  <a-spin :spinning="spinning">
    <div class="app-container">
      <a-card class="box-card">
        <a-form ref="goods" :rules="rules" :form="form" :layout="formLayout" >
          <a-row :gutter="[16,16]">
            <a-col :span="12">
              <a-form-item
                label="商品名称"
                prop="brief"
                :label-col="formItemLayout.labelCol"
                :wrapper-col="formItemLayout.wrapperCol"
              >
                <a-input
                  v-decorator="['brief', {rules: [{required: true, message: '必填项，请填写信息'}]}]"
                  allow-clear />
              </a-form-item>
            </a-col>
            <a-col :span="12" v-if="false">
              <a-form-item
                label="仓库内名称"
                prop="name"
                :label-col="formItemLayout.labelCol"
                :wrapper-col="formItemLayout.wrapperCol"
              >
                <a-input v-decorator="['name', {rules: [{required: true, message: '必填项，请填写信息'}, {max:50, message: '输入超出长度限制'}]}]" allow-clear />
              </a-form-item>
            </a-col>
            <a-col :span="12" v-if="false">
              <a-form-item
                label="条形码"
                prop="name"
                :label-col="formItemLayout.labelCol"
                :wrapper-col="formItemLayout.wrapperCol"
              >
                <a-input v-decorator="['barcode', {rules: [{required: true, message: '必填项，请填写信息'}, {max:20, message: '输入超出长度限制'}]}]" allow-clear disabled />
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item
                label="所属分类"
                :label-col="formItemLayout.labelCol"
                :wrapper-col="formItemLayout.wrapperCol"
              >
                <a-cascader
                  :options="categoryList"
                  v-decorator="['category', {rules: [{required: true, message: '必填项，请填写信息'}]}]"
                  placeholder="请选择"
                  @change="handleCategoryChange"
                  allow-clear
                  :field-names="{ label: 'name', value: 'code', children: 'children' }" />
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item
                :label-col="formItemLayout.labelCol"
                :wrapper-col="formItemLayout.wrapperCol"
                label="商品类型">
                <a-select
                  style="width: 100%"
                  placeholder="选择"
                  showSearch
                  allow-clear
                  v-decorator="['goods_type', {rules: [{required: true, message: '必填项，请填写信息'}]}]">
                  <a-select-option :key="key" :value="key" v-for="(val, key) in this.$Dictionaries.goods_type" :label="val">
                    {{ val }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item
                :label-col="formItemLayout.labelCol"
                :wrapper-col="formItemLayout.wrapperCol"
                label="商品单位">
                <a-select
                  style="width: 100%"
                  placeholder="选择"
                  allow-clear
                  show-search
                  :filter-option="false"
                  :not-found-content="null"
                  :default-active-first-option="false"
                  @search="handleSearchUnit"
                  @change="handleChangeUnit"
                  v-decorator="['unit', {rules: [{required: true, message: '必填项，请填写信息'}]}]">
                  <a-select-option :key="key" :value="key" v-for="(val, key) in this.$Dictionaries.unit" :label="val">
                    {{ val }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item
                label="序号"
                :label-col="formItemLayout.labelCol"
                :wrapper-col="formItemLayout.wrapperCol">
                <a-input-number allow-clear v-decorator="['serial', {initialValue: 1, rules: [{required: true, message: '必填项，请填写信息'}]}]"/>
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item
                label="品牌"
                :label-col="formItemLayout.labelCol"
                :wrapper-col="formItemLayout.wrapperCol">
                <a-input allow-clear v-decorator="['brand', {rules: [{required: true, message: '必填项，请填写信息'}]}]" />
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item
                :label-col="formItemLayout.labelCol"
                :wrapper-col="formItemLayout.wrapperCol"
                label="原价">
                <a-input-number
                  allow-clear
                  style="width: 70%"
                  v-decorator="['tag_price', {rules: [{required: true, message: '必填项，请填写信息'}]}]"
                  placeholder="0.00"
                  :precision="2"
                  :min="0">
                  <template slot="append">元</template>
                </a-input-number>
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item
                :label-col="formItemLayout.labelCol"
                :wrapper-col="formItemLayout.wrapperCol"
                label="现价">
                <a-input-number
                  style="width: 70%"
                  allow-clear
                  v-decorator="['price', {rules: [{required: true, message: '必填项，请填写信息'}]}]"
                  placeholder="0.00"
                  :precision="2"
                  :min="0.01"
                >
                  <template slot="append">元</template>
                </a-input-number>
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item
                label="商品图片"
                :label-col="formItemLayout.labelCol"
                :wrapper-col="formItemLayout.wrapperCol"
              >
                <a-upload
                  v-decorator="['picture', {rules: [{required: true, message: '必填项，请填写信息'}]}]"
                  name="file"
                  accept="image/png, image/jpeg, image/jpg"
                  :action="actionUrl"
                  list-type="picture-card"
                  :file-list="fileList"
                  :data="new_multipart_params"
                  :before-upload="beforeUpload"
                  @preview="handlePreview"
                  @change="handleChange"
                >
                  <div v-if="fileList.length < 1">
                    <a-icon type="plus" />
                    <div class="ant-upload-text">
                      上传
                    </div>
                  </div>
                </a-upload>
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item
                label="商品滚动图"
                :label-col="formItemLayout.labelCol"
                :wrapper-col="formItemLayout.wrapperCol"
              >
                <a-upload
                  v-decorator="['gallery', {rules: [{required: true, message: '必填项，请填写信息'}]}]"
                  name="file"
                  accept="image/png, image/jpeg, image/jpg"
                  :action="actionUrl"
                  list-type="picture-card"
                  :file-list="galleryList"
                  :data="gallery_new_multipart_params"
                  :before-upload="beforeGoodsGalleryUpload"
                  @preview="handlePreview"
                  @change="handleGoodsGalleryChange"
                >
                  <div v-if="galleryList.length < 6">
                    <a-icon type="plus" />
                    <div class="ant-upload-text">
                      上传
                    </div>
                  </div>
                </a-upload>
              </a-form-item>
            </a-col>
            <a-col :span="24">
              <a-form-item
                label="* 详细介绍"
                :label-col="buttonItemLayout.labelCol"
                :wrapper-col="buttonItemLayout.wrapperCol">
                <a-upload
                  v-show="false"
                  class="detail_upload"
                  name="file"
                  multiple
                  accept="image/png, image/jpeg, image/jpg"
                  :action="actionUrl"
                  list-type="picture-card"
                  :file-list="detail_icon_url"
                  :before-upload="beforeGoodsDetailUrlUpload"
                  @preview="handlePreview"
                  @change="handleDetailUrlChange"
                  :customRequest="detailCustomRequest"
                ></a-upload>
                <quill-editor
                  v-model="goods.detail"
                  ref="myQuillEditor"
                  :options="editorOption"
                  @blur="onEditorBlur($event)"
                  @focus="onEditorFocus($event)"
                  @ready="onEditorReady($event)"
                  @change="onEditorChange($event)">
                </quill-editor>
              </a-form-item>
            </a-col>
          </a-row>
        </a-form>
        <div>
          <a-row>
            <a-col :span="24"></a-col>
            <a-col :span="6" offset="8">
              <a-button @click="handleCancel">取消</a-button>
            </a-col>
            <a-col :span="6">
              <a-button type="primary" @click="handleEdit" :loading="loading">更新</a-button>
            </a-col>
          </a-row>
        </div>
      </a-card>
      <a-modal :visible="previewVisible" :footer="null" @cancel="handlePreviewCancel">
        <img alt="example" style="width: 100%" :src="previewImage" />
      </a-modal>
    </div>
  </a-spin>
</template>

<script>
import { detailGoods, editGoods, shopGoodsGalleryList } from '@/api/applet_goods'
import { goods_category_list } from '@/api/applet_goods_category'
import { oss_token_list } from '@/api/oss_token'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import TagSelectOption from '@/components/TagSelect/TagSelectOption'
import { quillEditor } from 'vue-quill-editor'
import { Quill } from 'vue-quill-editor/dist/ssr'
import axios from 'axios'
const pick = require('lodash.pick')
const fonts = [ 'Sans-Serif', 'SimSun', 'FangSong', 'Serif', 'Monospace' ]
Quill.imports['formats/font'].whitelist = fonts

function getBase64 (file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })
}

export default {
  name: 'GoodsEdit',
  components: { TagSelectOption, quillEditor },
  data: function () {
    return {
      formLayout: 'horizontal',
      request: undefined,
      actionUrl: 'https://static2.jnzbtz.com',
      new_multipart_params: {
        'key': '',
        'policy': '',
        'OSSAccessKeyId': '',
        'success_action_status': '200',
        'signature': ''
      },
      gallery_new_multipart_params: this.new_multipart_params,
      detail_icon_url_new_multipart_params: this.new_multipart_params,
      fileList: [],
      detail_icon_url: [],
      galleryList: [],
      form: this.$form.createForm(this),
      wms_inbound_count: 0,
      previewImage: '',
      previewVisible: false,
      spinning: true,
      loading: false,
      categoryList: [],
      goods: {
        category: undefined,
        gallery: []
      },
      rules: {
        name: [{ required: true, message: '商品名称不能为空', trigger: 'blur' }]
      },
      editorOption: {
        modules: {
          toolbar: {
            container: [
              ['bold', 'italic', 'underline', 'strike'], // toggled buttons
              ['blockquote', 'code-block'],
              [{ 'header': 1 }, { 'header': 2 }], // custom button values
              [{ 'list': 'ordered' }, { 'list': 'bullet' }],
              [{ 'script': 'sub' }, { 'script': 'super' }], // superscript/subscript
              [{ 'indent': '-1' }, { 'indent': '+1' }], // outdent/indent
              [{ 'direction': 'rtl' }], // text direction

              [{ 'size': ['small', false, 'large', 'huge'] }], // custom dropdown
              [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

              [{ 'color': [] }, { 'background': [] }], // dropdown with defaults from theme
              [{ 'font': fonts }],
              [{ 'align': [] }],

              ['clean'], // remove formatting button
              ['link', 'image']
            ],
            handlers: {
              image: function (value) {
                if (value) {
                  // 调用element的图片上传组件
                  const element = document.querySelector('.detail_upload input')
                  element.click()
                } else {
                  this.quill.format('image', false)
                }
              }
            }
          },
          history: {
            delay: 1000,
            maxStack: 50,
            userOnly: false
          }
        }
      }
    }
  },
  computed: {
    headers () {
      return {
        'X-Litemall-Admin-Token': '1'
      }
    },
    attributesData () {
      var attributesData = []
      for (var i = 0; i < this.attributes.length; i++) {
        if (this.attributes[i].deleted) {
          continue
        }
        attributesData.push(this.attributes[i])
      }
      return attributesData
    },
    formItemLayout () {
      const { formLayout } = this
      return formLayout === 'horizontal'
        ? {
          labelCol: { span: 4 },
          wrapperCol: { span: 14 }
        } : {}
    },
    buttonItemLayout () {
      const { formLayout } = this
      return formLayout === 'horizontal'
        ? {
          labelCol: { span: 3 },
          wrapperCol: { span: 18 }
        } : {}
    }
  },
  created () {
    this.init()
  },
  methods: {
    loadEditInfo (data) {
      if (data && data.detail) {
        data.detail = data.detail.replace('< ', '<')
      }
      data.tag_price = (data.tag_price / 100).toFixed(2)
      data.price = (data.price / 100).toFixed(2)

      const my_category = []
      this.categoryList.map(item => {
        if (item.code === data.category.substring(0, 2)) {
          my_category.push({
            code: item.code, id: item.id, level: item.level, name: item.name
          })
          item.children.map(item_child => {
            if (item_child.code === data.category.substring(0, 4)) {
              my_category.push({
                code: item_child.code, id: item_child.id, level: item_child.level, name: item_child.name
              })
            }
            item_child.children.map(item_child_child => {
              if (item_child_child.code === data.category) {
                my_category.push({
                  code: item_child_child.code, id: item_child_child.id, level: item_child_child.level, name: item_child_child.name
                })
              }
            })
          })
        }
      })

      data.category = my_category.map(item => {
        return item.code
      })
      data.detail = this.formatHtml()
      data.goods_type = '' + data.goods_type
      data.gallery = this.galleryList
      const formData = pick(data, ['id', 'brand', 'serial', 'barcode', 'detail', 'goods_type',
        'brief', 'picture', 'gallery', 'category', 'name', 'unit', 'tag_price', 'price', 'show_on_index'])
      this.id = formData.id
      this.fileList = formData.picture
        ? [{
          url: formData.picture,
          uid: '1',
          name: 'xxx.png',
          status: 'done',
          response: 'Server Error 500'
        }]
        : []
      this.form.setFieldsValue(formData)
      this.$refs.myQuillEditor.quill.innerHTML = data.detail
      // this.goods.picture = formData.picture
      // this.goods.gallery = formData.gallery
    },
    onEditorBlur (quill) {
      console.log('editor blur!', quill)
    },
    onEditorFocus (quill) {
      console.log('editor focus!', quill)
    },
    onEditorReady (quill) {
      console.log('editor ready!', quill)
      this.form.setFieldsValue({
        detail: this.goods.detail
      })
    },
    onEditorChange ({ quill, html, text }) {
      console.log('editor change!', quill, html, text)
      this.$emit('change', html)
    },
    init () {
      this.initOss()
      this.initCategory()
    },
    async initOss () {
      this.new_multipart_params = await this.ossToken()
      this.detail_icon_url_new_multipart_params = await this.ossToken()
      this.gallery_new_multipart_params = await this.ossToken()
    },
    ossToken () {
      return new Promise((resolve, reject) => {
        oss_token_list().then(({ data }) => {
          const multipart_params = {
            OSSAccessKeyId: data.accessid,
            policy: data.policy,
            signature: data.signature
          }
          resolve(multipart_params)
        }).catch((error) => {
          reject(error)
        })
      })
    },
    initCategory () {
      goods_category_list().then(res => {
        const entries = res.data.entries
        const level1 = []
        const level2 = []
        const level3 = []
        entries.forEach((e, i) => {
          if (e.level === 1) {
            level1.push(e)
          } else if (e.level === 2) {
            level2.push(e)
          } else {
            level3.push(e)
          }
        })

        level1.forEach((e, i) => {
          e.children = []
          level2.forEach((e2, i2) => {
            e2.children = []
            if (e.code === e2.code.substr(0, 2)) {
              e.children.push(e2)
            }
            level3.forEach((e3, i3) => {
              if (e2.code === e3.code.substr(0, 4)) {
                e2.children.push(e3)
              }
            })
          })
        })
        res.data.entries = level1
        this.categoryList = res.data.entries
      }).finally(() => {
        this.goodsGallery()
      })
    },
    goodsGallery () {
      shopGoodsGalleryList({ goods_id: this.$route.query.id })
        .then(res => {
          const gallery = res.data.entries
          if (gallery) {
            gallery.forEach((g, i) => {
              this.galleryList.push({
                url: g.value,
                uid: g.id + '',
                name: g.value.substr(g.value.lastIndexOf('/') + 1),
                status: 'done',
                response: 'Server Error 500'
              })
            })
          }
        }).finally(() => {
          this.loadGoodsDetail()
      })
    },
    loadGoodsDetail () {
      detailGoods({ goods_id: this.$route.query.id }).then(response => {
        const res = response.data
        if (res && res.entries && res.entries.length > 0) {
          this.goods = res.entries[0]
          this.loadEditInfo(this.goods)
        }
      }).finally(() => {
        this.spinning = false
      })
    },
    formatHtml () {
      const str = this.goods.detail
      let temp = ''
      if (str === undefined || str.length === 0) return ''
      temp = str.replace(/&amp;/g, '&')
      temp = temp.replace(/&lt;/g, '<')
      temp = temp.replace(/&gt;/g, '>')
      temp = temp.replace(/&nbsp;/g, '')
      // eslint-disable-next-line no-useless-escape
      temp = temp.replace(/&#39;/g, `\'`)
      temp = temp.replace(/&quot;/g, '"')
      temp = temp.replaceAll('< ', '<')
      return temp
    },
    handleCategoryChange (value) {
      if (value.length !== 3) {
        this.$message.warning('所属分类必须三级')
        return
      }
      this.goods.category = value[2]
    },
    handleCancel: function () {
      this.$router.go(-1)
    },
    handleEdit () {
      this.form.validateFields((errors, values) => {
        if (!errors) {
          // 新增
          if (this.goods.detail === undefined) {
            this.$message.warning('填写商品详情')
            return
          }
          if (this.fileList.length === 0 || this.galleryList.length === 0) {
            this.$message.warning('上传图片')
            return
          }
          this.goods = {
            ...values,
            detail: this.goods.detail
          }
          if (this.goods.category.length === 3) {
            this.goods.category = this.goods.category[2]
          } else {
            this.$message.warning('商品分类必须是三级')
            return
          }
          this.goods.tag_price = (values.tag_price * 100).toFixed(0)
          this.goods.price = (values.price * 100).toFixed(0)
          this.confirmLoading = true
          if (this.fileList.length > 0) {
            this.fileList.forEach((item, index) => {
              if (item.url) {
                this.goods.picture = item.url
              } else {
                this.goods.picture = (this.actionUrl + '/' + item.uid + '_' + item.lastModified + '.' + item.name.substring(item.name.lastIndexOf('.') + 1))
              }
            })
          }
          if (this.galleryList.length > 0) {
            this.goods.gallery = []
            this.galleryList.forEach((item, index) => {
              if (item.url) {
                this.goods.gallery.push(item.url)
              } else {
                this.goods.gallery.push(this.actionUrl + '/' + item.uid + '_' + item.lastModified + '.' + item.name.substring(item.name.lastIndexOf('.') + 1))
              }
            })
          }
          this.loading = true
          editGoods(this.goods, this.id)
            .then(response => {
              this.confirmLoading = false
              this.loading = false
              if (response.code === 1000) {
                this.$router.push({ path: '/applet_goods/goods_list' })
              }
            }).catch((error) => {
            console.log(error)
            this.confirmLoading = false
              this.loading = false
              this.goods.tag_price = (this.goods.tag_price / 100).toFixed(0)
              this.goods.price = (this.goods.price / 100).toFixed(0)
            })
        }
      })
    },
    beforeUpload (file) {
      const name = file.name
      const suffix = name.substring(name.lastIndexOf('.') + 1)
      const lastName = file.lastModified + '.' + suffix
      this.new_multipart_params.key = file.uid + '_' + lastName
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
      if (!isJpgOrPng) {
        this.$message.error('你只能上传jpeg/png格式的图片')
      }
      const isLt2M = file.size / 1024 / 1024 < 5
      if (!isLt2M) {
        this.$message.error('文件过大，请重新上传')
      }
      return isJpgOrPng && isLt2M
    },
    beforeGoodsDetailUrlUpload (file) {
      const name = file.name
      const suffix = name.substring(name.lastIndexOf('.') + 1)
      const lastName = file.lastModified + '.' + suffix
      this.detail_icon_url_new_multipart_params.key = file.uid + '_' + lastName
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
      if (!isJpgOrPng) {
        this.$message.error('你只能上传jpeg/png格式的图片')
      }
      const isLt2M = file.size / 1024 / 1024 < 5
      if (!isLt2M) {
        this.$message.error('文件过大，请重新上传')
      }
      if (isJpgOrPng && isLt2M) {
        this.detailCustomRequest(file)
      }
      return isJpgOrPng && isLt2M
    },
    beforeGoodsGalleryUpload (file) {
      const name = file.name
      const suffix = name.substring(name.lastIndexOf('.') + 1)
      const lastName = file.lastModified + '.' + suffix
      this.gallery_new_multipart_params.key = file.uid + '_' + lastName
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
      if (!isJpgOrPng) {
        this.$message.error('你只能上传jpeg/png格式的图片')
      }
      const isLt2M = file.size / 1024 / 1024 < 5
      if (!isLt2M) {
        this.$message.error('文件过大，请重新上传')
      }
      return isJpgOrPng && isLt2M
    },
    // 进口图片
    async handlePreview (file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj, () => {})
      }
      this.previewImage = file.url || file.preview || file.thumbUrl
      this.previewVisible = true
    },
    handlePreviewCancel () {
      this.previewImage = ''
      this.previewVisible = false
    },
    handleChange ({ fileList, file }) {
      this.fileList = fileList
      if (file.status === 'removed') {
        this.$message.error('请重新上传')
      }
      // if (file.status === 'done') {
      //   this.goods.picture = this.actionUrl + '/' + this.new_multipart_params.key
      // }
    },
    handleGoodsGalleryChange ({ fileList, file }) {
      this.galleryList = fileList
      let index = -1
      if (this.galleryList.indexOf(file) > 0) {
        index = this.galleryList.indexOf(file)
      }
      if (file.status === 'removed' && index > 0) {
        this.galleryList.splice(index, 1)
        this.$message.error('请重新上传')
      }
      if (file.status === 'done' && index > 0) {
        this.galleryList[index].url = this.actionUrl + '/' + this.gallery_new_multipart_params.key
      }
    },
    handleDetailUrlChange ({ fileList, file }) {
      this.detail_icon_url = fileList
      if (file.status === 'removed') {
        this.goods.detail_icon_url = undefined
        this.$message.error('请重新上传')
      }
      if (file.status === 'done') {
        // this.goods.detail_icon_url.push(this.actionUrl + '/' + this.detail_icon_url_new_multipart_params.key)
        // 获取富文本组件实例
        const quill = this.$refs.myQuillEditor.quill
        // 如果上传成功
        if (file.status === 'done') {
          // 获取光标所在位置
          const length = quill.getSelection().index
          // 插入图片，res为服务器返回的图片链接地址
          quill.insertEmbed(length, 'image', this.actionUrl + '/' + this.detail_icon_url_new_multipart_params.key)
          // 调整光标到最后
          quill.setSelection(length + 1)
        } else {
          // 提示信息，需引入Message
          this.$message.error('图片插入失败！')
        }
      }
    },

    detailCustomRequest (file) {
      setTimeout(() => {
        if (file !== undefined) {
          const fileList = []
          fileList.push({ uid: file.uid, lastModified: file.lastModified, name: file.name, file: file, status: 'done' })
          fileList.forEach((item) => {
            if (item.file.uid === undefined) {
              return
            }
            // 对上传的文件做大小的校验 不能大于20M并且不能超过10张
            const formData = new FormData()
            const name = item.file.name
            const suffix = name.substr(name.lastIndexOf('.') + 1)
            const lastName = item.file.lastModified + '.' + suffix
            const key = item.file.uid + '_' + lastName
            formData.append('policy', this.detail_icon_url_new_multipart_params.policy)
            formData.append('OSSAccessKeyId', this.detail_icon_url_new_multipart_params.OSSAccessKeyId)
            formData.append('success_action_status', '200')
            formData.append('signature', this.detail_icon_url_new_multipart_params.signature)
            formData.append('key', key)
            formData.append('file', item.file)
            if (this.request === undefined) {
              this.request = axios.create({
                // API 请求的默认前缀
                baseURL: this.actionUrl,
                timeout: 10000 // 请求超时时间
              })
            }
            this.request({
              baseURL: this.actionUrl,
              method: 'post',
              data: formData
            }).then((Res) => {
              const pic = {
                thumbUrl: this.actionUrl + '/' + key,
                uid: item.file.uid,
                status: 'done',
                name: item.file.name,
                ...item.file
              }
              this.detail_icon_url_new_multipart_params = pic
              const quill = this.$refs.myQuillEditor.quill
              // 获取光标所在位置
              const length = quill.getSelection().index
              // 插入图片，res为服务器返回的图片链接地址
              quill.insertEmbed(length, 'image', pic.thumbUrl)
              // 调整光标到最后
              quill.setSelection(length + 1)
            }).finally(() => {
              oss_token_list().then(({ data }) => {
                this.detail_icon_url_new_multipart_params.OSSAccessKeyId = data.accessid
                this.detail_icon_url_new_multipart_params.policy = data.policy
                this.detail_icon_url_new_multipart_params.signature = data.signature
              })
            })
          })
        }
      }, 2000)
    },
    handleSearchUnit (value) {
      const storageUnits = this.$Dictionaries.unit
      if (value !== undefined) {
        const unitsObj = {}
        for (const unit in storageUnits) {
          const name = storageUnits[unit]
          if (name.includes(value)) {
            unitsObj[unit] = name
          }
        }
        this.units = unitsObj
      } else {
        this.units = storageUnits
      }
    },
    handleChangeUnit (value) {
      if (value === undefined) {
        this.units = this.$Dictionaries.unit
      }
    }
  }
}
</script>
<style>
.el-card {
  margin-bottom: 10px;
}
.el-tag + .el-tag {
  margin-left: 10px;
}
.input-new-keyword {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #20a0ff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 120px;
  height: 120px;
  line-height: 120px;
  text-align: center;
}
.avatar {
  width: 145px;
  height: 145px;
  display: block;
}
.op-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
/*谷歌*/
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
/*火狐*/
input[type="number"] {
  -moz-appearance: textfield;
}
</style>
